<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    :max-width="900"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Coberturas disponiveis</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="closeModal">
          <v-icon>fa-solid fa-xmark</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="b-dialog--content mt-4 px-0 pb-0">
        <v-data-table
          dense
          disable-sort
          hide-default-footer
          item-key="id"
          :headers="headers"
          :items="items"
        >
          <template v-slot:[`item.coveragePercentage`]="{ item }">
            {{ formatPercentage(item.coveragePercentage) }}
          </template>

          <template v-slot:[`item.coverageValueLimit`]="{ item }">
            {{ formatMoney(item.coverageValueLimit) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatMoney, formatPercentage } from '@/helpers/formatting';

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    headers: [
      { text: 'Cobertura', value: 'name' },
      { text: 'Percentual', value: 'coveragePercentage' },
      { text: 'Valor limite', value: 'coverageValueLimit' }
    ]
  }),

  methods: {
    formatMoney,
    formatPercentage,

    closeModal() {
      this.$emit('closeModal', false);
    }
  }
};
</script>
